@import ../../styles/helpers

.section
    overflow: hidden

.wrapper
    position: relative

.title
    margin-bottom: 64px
    +m
        margin-bottom: 48px

.inner
    margin: 0 -16px
    +m
        margin: 0 -8px 

.card
    width: 100%
